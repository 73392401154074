import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Tabs,
  Tag,
  Space,
  Form,
  Input,
  DatePicker,
  Select,
  Modal,
  Upload,
  message,
} from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, InboxOutlined,SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopMenu from './components/TopMenu';
import './App.css';
import moment from 'moment';

const { TabPane } = Tabs;
const { Option } = Select;
const { Dragger } = Upload;

const ProjectPage: React.FC = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingProject, setEditingProject] = useState<any>(null);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [projects, setProjects] = useState<any[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<any[]>([]); // For search functionality
  const [searchQuery, setSearchQuery] = useState(''); // Search query state

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const apiUrl = process.env.REACT_APP_API_URL; // Base URL from environment variables

  // Fetch projects on component mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const action = params.get('action');
    if (action === 'create') {
      setIsCreating(true);
    }else{
    fetchProjects();
    }
  }, [location]);

  // Fetch all projects from the API
  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/v1/projects`);
      const transformedProjects = (response.data.projects || []).map((project: any) => ({
        key: project.id,
        projectName: project.name || 'Unnamed Project',
        status: project.status || 'N/A',
        leadContact: project.creator || 'N/A',
        location: project.location || 'N/A',
        users: project.users || 'N/A',
        startDate: project.startDate
        ? new Date(project.startDate).toLocaleDateString() // Format date
        : 'N/A',
      endDate: project.endDate
        ? new Date(project.endDate).toLocaleDateString() // Format date
        : 'N/A',
      }));
      setProjects(transformedProjects);
      setFilteredProjects(transformedProjects); // Sync filteredProjects with projects
    } catch (error) {
      console.error('Error fetching projects:', error);
      message.error('Failed to load projects. Please try again.');
    }
  };

  // Fetch a single project by ID
  const fetchProjectById = async (id: string) => {
    try {
      const response = await axios.get(`${apiUrl}/api/v1/projects/${id}`);
      const project = response.data;
      setEditingProject(project);
      form.setFieldsValue({
        projectName: project.name,
        location: project.location,
        status: project.status,
        leadContact: project.creator,
        users: project.users,
        startDate: project.startDate ? moment(project.startDate) : null, // Convert to moment
        endDate: project.endDate ? moment(project.endDate) : null, // Convert to moment
      });
    } catch (error) {
      console.error('Error fetching project by ID:', error);
      message.error('Failed to fetch project details. Please try again.');
    }
  };

  // Create or update a project
  const handleFormSubmit = async (values: any) => {
    try {
      const payload = {
        ...values,
        startDate: values.startDate ? values.startDate.toISOString() : null, // Convert to ISO string
        endDate: values.endDate ? values.endDate.toISOString() : null, // Convert to ISO string
      };
  
      if (isEditing && editingProject) {
        // Update project
        await axios.put(`${apiUrl}/api/v1/projects/${editingProject.id}`, payload);
        message.success('Project updated successfully!');
      } else {
        // Create project
        await axios.post(`${apiUrl}/api/v1/projects`, payload);
        message.success('Project created successfully!');
      }
  
      setIsCreating(false);
      setIsEditing(false);
      setEditingProject(null);
      form.resetFields();
      navigate('/project', { replace: true }); // Navigate without query parameter
      fetchProjects(); // Reload projects
    } catch (error) {
      console.error('Error creating/updating project:', error);
      message.error('Failed to save project. Please try again.');
    }
  }; 

  // Handle edit project
  const handleEditProject = (project: any) => {
    setIsEditing(true);
    setIsCreating(false);
    fetchProjectById(project.key); // Fetch project details by ID
  };

  const handleDeleteProject = async (id: string) => {
    try {
      await axios.delete(`${apiUrl}/api/v1/projects/${id}`); // Delete project by ID
      message.success('Project deleted successfully!');
      fetchProjects(); // Refresh the list of projects after deletion
    } catch (error) {
      console.error('Error deleting project:', error);
      message.error('Failed to delete the project. Please try again.');
    }
  };  

  // Toggle form view for creating a new project
  const handleCreateProjectClick = () => {
    setIsCreating(true);
    setIsEditing(false);
    setEditingProject(null);
    form.resetFields();
  };

  // Handle cancel button in form
  const handleCancel = () => {
    setIsCreating(false);
    setIsEditing(false);
    setEditingProject(null);
    form.resetFields();
    navigate('/project', { replace: true }); // Navigate without query parameter
  };

  // Handle Upload button click
  const handleUploadClick = (projectKey: string) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf'; // Accept only PDF files
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file) {
        handleUploadFile(file, projectKey);
      }
    };
    input.click();
  };
    // Handle search input
    const handleSearch = (value: string) => {
      setSearchQuery(value);
      if (value.trim() === '') {
        setFilteredProjects(projects); // Reset to full list
      } else {
        const filtered = projects.filter((project) =>
          project.projectName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredProjects(filtered);
      }
    };
    

  // Inside ProjectPage
const handleUploadFile = async (file: any, projectKey: string) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('project_id', projectKey);
  
      const response = await axios.post(`${apiUrl}/api/v1/projects/${projectKey}/files`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      if (response.status === 200) {
        message.success('File uploaded successfully!');
        navigate(`/specification?projectId=${projectKey}`); // Navigate to the specification page
      } else {
        message.error('File upload failed. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('File upload failed. Please try again.');
    }
  };

  // Close the Upload Modal
  const handleCancelUpload = () => {
    setIsUploadModalVisible(false);
  };

  // Columns for the table
  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 200, 
      render: (text: string, record: any) => (
        <Link to={`/specification?projectId=${record.key}`} style={{ textDecoration: 'underline', color: 'blue' }}>
          {text}
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120, // Static width for the column
      render: (status: string) => (
        <Tag color={status === 'Open' ? 'blue' : status === 'In Progress' ? 'orange' : 'green'}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Assigned',
      dataIndex: 'leadContact',
      key: 'leadContact',
      width: 150, // Static width for the column
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      width: 80, // Static width for the column
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 150, // Static width for the column
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 150, // Static width for the column
    },
    {
      title: 'Action',
      key: 'action',
      width: 200, // Static width for the column
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => handleEditProject(record)} />
      {/* <Button
        icon={<UploadOutlined />}
        onClick={() => handleUploadClick(record.key)} // Pass the project ID to the upload handler
      /> */}
      <Button
        icon={<DeleteOutlined />}
        danger
        onClick={() => {
          const confirmed = window.confirm('Are you sure you want to delete this project?');
          if (confirmed) {
            handleDeleteProject(record.key);
          }
        }}
      />
        </Space>
      ),
    },
  ];

  // Upload configuration
  const uploadProps = {
    name: 'file',
    multiple: true,
    action: '/upload.do',
    onChange(info: any) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  // Upload Document Modal
  const UploadDocumentModal = () => (
    <Modal
      title="Upload Specification File"
      visible={isUploadModalVisible}
      onCancel={handleCancelUpload}
      footer={[
        <Button key="cancel" onClick={handleCancelUpload}>
          Cancel
        </Button>,
        <Button key="getLog" type="primary" onClick={() => navigate('/specification')}>
          Get Log
        </Button>,
      ]}
    >
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click to browse or drop here to upload. Supported Format: PDF.</p>
        <p className="ant-upload-hint">
          Maximum Individual File Size: <strong>100 MB</strong>.
          <br />
          A maximum of <strong>200 files</strong> can be uploaded at a time.
        </p>
      </Dragger>
    </Modal>
  );

  // Form layout for creating or editing a project
  const CreateProjectForm = () => (
    <Form layout="vertical" className="create-project-form" form={form} onFinish={handleFormSubmit}>
  <h2>{isEditing ? 'Edit Project' : 'Create Project'}</h2>
  <Form.Item
    label={
      <>
        Project Name <span className="mandatory">*</span>
      </>
    }
    name="projectName"
    rules={[{ required: true, message: 'Please enter a project name' }]}
  >
    <Input placeholder="Enter Project Name" />
  </Form.Item>
  <Form.Item
    label={
      <>
        Location <span className="mandatory">*</span>
      </>
    }
    name="location"
    rules={[{ required: true, message: 'Please enter a location' }]}
  >
    <Input placeholder="Enter an Address or Zip Code" />
  </Form.Item>
  <Form.Item
    label="Status"
    name="status"
  >
    <Select placeholder="Select Status">
        <Option value="Conceptual">Conceptual</Option>
        <Option value="Design">Design</Option>
        <Option value="Final Planning">Final Planning</Option>
        <Option value="Pre-Construction/Negotiated">Pre-Construction/Negotiated</Option>
        <Option value="GC Bidding">GC Bidding</Option>
        <Option value="Sub-Bidding">Sub-Bidding</Option>
        <Option value="Post-Bid">Post-Bid</Option>
        <Option value="Bid Results">Bid Results</Option>
        <Option value="Award">Award</Option>
        <Option value="Under Construction">Under Construction</Option>
        <Option value="Occupancy">Occupancy</Option>
        <Option value="Project Abandoned">Project Abandoned</Option>
    </Select>
  </Form.Item>
  <Form.Item
    label="Assigned"
    name="leadContact"
  >
    <Input placeholder="Enter Assigned Contact" />
  </Form.Item>
  <Form.Item
    label="Users"
    name="users"
  >
    <Input type="number" placeholder="Enter Number of Users" />
  </Form.Item>
 <div style={{ display: 'flex', gap: '20px' }}>
    <Form.Item
      label="Start Date"
      name="startDate"
      style={{ flex: 1 }}
    >
      <DatePicker placeholder="Select Start Date" style={{ width: '100%' }} />
    </Form.Item>

    <Form.Item
      label="End Date"
      name="endDate"
      style={{ flex: 1 }}
    >
      <DatePicker placeholder="Select End Date" style={{ width: '100%' }} />
    </Form.Item>
  </div>
  <Form.Item style={{ textAlign: 'right', gap: '15px', display: 'flex', justifyContent: 'flex-end' }}>
    <Button type="default" onClick={handleCancel} style={{ marginRight: '15px' }}>
      Cancel
    </Button>
    <Button type="primary" htmlType="submit">
      {isEditing ? 'Update Project' : 'Create Project'}
    </Button>
  </Form.Item>
</Form>
  );

  return (
    <div>
      <TopMenu projects={projects} />
      <div className="project-page">
      {!isCreating && !isEditing && (
     <div style={{ marginBottom: '20px' }}>
     {/* Row with Projects heading and Create Project button */}
     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
     <h1 style={{ fontSize: '24px', fontWeight: 'bold', margin: 0 }}>Projects</h1>
       <Button
         type="primary"
         icon={<PlusOutlined />}
         className="create-project-btn"
         onClick={handleCreateProjectClick}
         style={{ padding: '6px 15px', fontSize: '16px' }}
       >
         Create Project
       </Button>
     </div>
   
     {/* Search Projects input */}
     <Input
       placeholder="Search Projects"
       prefix={<SearchOutlined />}
       value={searchQuery}
       onChange={(e) => handleSearch(e.target.value)}
       style={{width: '100%',maxWidth: '400px',borderRadius: '4px',padding: '6px',marginBottom: '10px'}}
     />
   </div>   
        )}
        {!isCreating && !isEditing ? (
          <Tabs defaultActiveKey="1">
            <TabPane tab="All Projects" key="1">
              <Table dataSource={filteredProjects} columns={columns} pagination={false} scroll={{ x: 1200 }} rowClassName="custom-row-height"  />
            </TabPane>
          </Tabs>
        ) : (
          <CreateProjectForm />
        )}
      </div>
      <UploadDocumentModal />
    </div>
  );
};

export default ProjectPage;