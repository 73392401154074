import React, { useEffect, useState } from 'react';
import { Button, Table, Form, Upload, message, Space,Alert,Breadcrumb,Divider,Tooltip  } from 'antd';
import { UploadOutlined, RobotOutlined, DeleteOutlined,InfoCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import TopMenu from './components/TopMenu';

const SpecificationPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState<string | null>(null);
  const [selectedProjectName, setSelectedProjectName] = useState<string>('Projects');
  const [files, setFiles] = useState<any[]>([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [extractingStatus, setExtractingStatus] = useState<{ [key: string]: boolean }>({});
  const apiUrl = process.env.REACT_APP_API_URL; // Base URL

// Extract projectId from URL query
const params = new URLSearchParams(location.search);
const projectIdFromQuery = params.get('projectId');

// Load projects and initialize project data on page load or when projectId changes
useEffect(() => {
  const initializeData = async () => {
      await fetchProjects();
    // Fetch files if projectId is available
    if (projectIdFromQuery) {
      setProjectId(projectIdFromQuery);
      fetchFiles(projectIdFromQuery);
    } else {
      setFiles([]); // Clear files if no projectId is present
      setSelectedProjectName(''); // Clear selected project name when no projectId
    }
  };
  initializeData(); // Call the async function inside useEffect
}, [projectIdFromQuery]); // Run effect when projectId or projects list changes

// Set project name once projects are loaded
useEffect(() => {
  if (projectIdFromQuery && projects.length > 0) {
    const matchedProject = projects.find((project) => project.id === projectIdFromQuery);
    setSelectedProjectName(matchedProject?.name || 'Unknown Project');
  }
}, [projectIdFromQuery, projects]);

  // Fetch projects from the API
  const fetchProjects = async () => {
    if (loadingProjects) return; // Prevent duplicate calls
    setLoadingProjects(true);
    try {
      const response = await axios.get(`${apiUrl}/api/v1/projects`);
      setProjects(response.data.projects || []);
    } catch (error) {
      console.error('Error fetching projects:', error);
      message.error('Failed to load projects.');
    } finally {
      setLoadingProjects(false);
    }
  };

  // Fetch files for a specific project
  const fetchFiles = async (projectId: string) => {
    if (loadingFiles) return; // Prevent duplicate calls
    setLoadingFiles(true);
    try {
      const response = await axios.get(`${apiUrl}/api/v1/projects/${projectId}/files`);
      const apiFiles = response.data.files || [];
      if (apiFiles.length === 0) {
        setFiles([]); // Set an empty array if no files are returned
        return;
      }
  
      const formattedFiles = apiFiles
        .filter((file: any) => file.file_type === 'file')
        .map((file: any) => {
          const matchingSubmittal = apiFiles.find(
            (submittal: any) =>
              submittal.file_type === 'submittal' && submittal.document_id === file.id
          );

          return {
            key: file.id,
            specName: file.file_name,
            lastUser: 'Admin',
            date: new Date(file.upload_date).toLocaleDateString(),
            version: file.version,
            revision: 'N/A',
            project_id: file.project_id,
            file_id: file.id,
            submittalFileName: matchingSubmittal?.file_name || null,
            submittalFileUrl: matchingSubmittal?.file_url || null,
          };
        });
  
      setFiles(formattedFiles);
    } catch (error) {
      console.error('Error fetching files:', error);
      message.error('Failed to load files for this project.');
    } finally {
      setLoadingFiles(false);
    }
  };
  

  // Handle file upload
  const handleFileUpload = async ({ file }: any) => {
    if (!projectId) {
      message.error('Please select a project before uploading a file.');
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', 'file');
    formData.append('file_path', '/');

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/projects/${projectId}/files`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        message.success('File uploaded successfully!');
        fetchFiles(projectId); // Refresh files in the grid
      } else {
        message.error('Failed to upload file. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('Failed to upload file. Please try again.');
    } finally {
      setUploading(false);
    }
  };
  
  const handleDeleteFile = async (projectId: string, fileId: string) => {
    try {
      await axios.delete(`${apiUrl}/api/v1/projects/${projectId}/files/${fileId}`);
      message.success('File deleted successfully!');
      fetchFiles(projectId); // Reload the table after successful deletion
    } catch (error) {
      console.error('Error deleting file:', error);
      message.error('Failed to delete the file. Please try again.');
    }
  };

  // Handle Extract button click
  const handleExtractFile = async (project_id: string, file_id: string) => {
    setExtractingStatus((prev) => ({ ...prev, [file_id]: true })); // Set extracting state
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/projects/${project_id}/files/${file_id}?action=generatesubmittallist&discipline=architecture`
      );
  
      if (response.status === 201) {
        const submittalFileUrl = response.data.submittal_data;
  
       // Extract the filename from the submittalFileUrl
        const fileName = decodeURIComponent(submittalFileUrl.split('/').slice(-2, -1)[0]);

  
        // Update the file entry with the submittal link and file name
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.file_id === file_id
              ? { ...file, submittalFileName: fileName, submittalFileUrl }
              : file
          )
        );
  
        message.success('Submittal list generated successfully!');
      } else {
        message.error('Failed to generate submittal list.');
      }
    } catch (error) {
      console.error('Error generating submittal list:', error);
      message.error('Failed to generate submittal list.');
    } finally {
      setExtractingStatus((prev) => ({ ...prev, [file_id]: false })); // Reset extracting state
    }
  };    

  const handleDownloadFile = async (projectId: string, fileId: string,fileType: string) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/projects/${projectId}/files/${fileId}?type=${fileType}`
      );
      if (response.status === 200 && response.data.presigned_url) {
        // Redirect to presigned URL
        window.location.href = response.data.presigned_url;
      } else {
        throw new Error("Failed to fetch the presigned URL.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      message.error("Failed to download the file. Please try again.");
    }
  };

  // Table columns configuration
  const columns = [
    {
      title: 'Spec Name',
      dataIndex: 'specName',
      key: 'specName',
      width: 250,
      render: (_: any, record: any) => (
        <button
          onClick={() =>
            handleDownloadFile(record.project_id, record.file_id, 'file')
          }
          className="download-button"
          aria-label={`Download ${record.specName}`}
        >
          {record.specName}
        </button>
      ),
    },
    {
      title: 'Submittal List',
      dataIndex: 'submittalList',
      key: 'submittalList',
      width: 250,
      render: (_: any, record: any) =>
        record.submittalFileName ? (
          <button
            onClick={() =>
              handleDownloadFile(record.project_id, record.file_id, 'submittal')
            }
            className="download-button"
            aria-label={`Download ${record.submittalFileName}`}
          >
            {record.submittalFileName}
          </button>
        ) : (
          <Button
            className="extract-btn"
            loading={extractingStatus[record.file_id] || false}
            onClick={() => handleExtractFile(record.project_id, record.file_id)}
          >
            Extract
          </Button>
        ),
    },    
    { title: 'Last User', dataIndex: 'lastUser', key: 'lastUser', width: 150  },
    { title: 'Uploaded Date', dataIndex: 'date', key: 'date', width: 150  },
    { title: 'Version', dataIndex: 'version', key: 'version', width: 100 },
    { title: 'Revision', dataIndex: 'revision', key: 'revision', width: 100 },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => {
              const confirmed = window.confirm('Are you sure you want to delete this file?');
              if (confirmed) {
                handleDeleteFile(record.project_id, record.file_id);
              }
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <TopMenu
        projects={projects.map((project: any) => ({
          key: project.id,
          projectName: project.name,
        }))}
        selectedProjectName="Projects"
      />  
      <Divider style={{ margin: '0' }} /> {/* Divider component */}
      <div className="specification-page">
         {/* Breadcrumb */}
         {projectId && (
         <Breadcrumb style={{ marginBottom: '20px' }}>
         {/* Projects Breadcrumb Item */}
         <Breadcrumb.Item>
           <span
             onClick={() => navigate('/project')}
             className="breadcrumb-link"
           >
             Projects
           </span>
         </Breadcrumb.Item>
       
         {/* Selected Project Breadcrumb Item */}
         {selectedProjectName && (
           <Breadcrumb.Item>
             <span
               onClick={() => {
                 // Simulate project change click
                 const params = new URLSearchParams(location.search);
                 params.set('projectId', projectId);
                 navigate(`/specification?${params.toString()}`);
               }}
               className="breadcrumb-link"
             >
               {selectedProjectName}
             </span>
           </Breadcrumb.Item>
         )}
       </Breadcrumb>       
        )}
        {projectId && (
          <Form layout="vertical" className="spec-form">
            <div className="form-row">
              <div className="form-item-inline">
                <Upload
                  customRequest={handleFileUpload}
                  showUploadList={false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    className="upload-btn"
                    loading={uploading}
                    type="primary"
                  >
                    Upload a Specification File
                  </Button>
                </Upload>
              </div>
            </div>
          </Form>
        )}
  
        <div className="specifications-container">
          {/* {projectId ? ( */}
            <div>
             <h2 className="common-heading">Specifications</h2>
              <Table
                dataSource={files}
                columns={columns}
                className="common-table"
                loading={loadingFiles}
                rowKey={(record) => record.key}
                scroll={{ x: 1000 }} // Enable horizontal scroll for the table
              />
            </div>
          {/* ) : (
            <Alert
              message="Please select a project to continue"
              description="Select a project from the dropdown to view its specifications and submittal lists, or click 'Create Project' to add a new one."
              type="info"
              showIcon
              icon={<InfoCircleOutlined />}
              style={{ marginTop: '20px' }}
            />
          )} */}
        </div>
  
        <div>
        <Tooltip title="Coming soon">
          <Button icon={<RobotOutlined />} className="chat-bot-btn">
            Chat Bot
          </Button>
        </Tooltip>
        </div>
      </div>
    </div>
  );  
};
export default SpecificationPage;